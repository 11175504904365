import React from 'react';


import {profileImage} from "../utils/profile";
import styled from "styled-components";
export const ProfileImage = styled.img`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  margin-left: ${props => props.marginLeft}px;
`;
function Avatar({avatar, src,size, marginLeft}) {

    avatar = (avatar && avatar.constructor === Object && Object.keys(avatar).length === 0)?null:avatar;
    return (
        <ProfileImage
            size={size || 49}
            marginLeft={marginLeft || (marginLeft===0)?0:10}
            src={profileImage(avatar)}

        />
    );
}

export default Avatar;
