import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector} from "react-redux";

import { Search, AutoComplete } from "../styles/explore";
import {PeopleFlex, PeopleDetails} from "../styles/profile";

import styled from "styled-components";


import { SearchOutlined, MessageOutlined, WalletOutlined} from '@ant-design/icons';
import {exploreSearch, search} from "../../requests/ava";
import AvatarProfile from "../Avatar";
import NotificationBtn from "../NotificationBtn";
import {Avatar, Badge, Space} from "antd";

const dark = [
  "M15.692 11.205l6.383-7.216c.45-.45.45-1.18 0-1.628-.45-.45-1.178-.45-1.627 0l-7.232 6.402s.782.106 1.595.93c.548.558.882 1.51.882 1.51z",
  "M17.45 22.28H3.673c-1.148 0-2.083-.946-2.083-2.11V7.926c0-1.165.934-2.112 2.082-2.112h5.836c.414 0 .75.336.75.75s-.336.75-.75.75H3.672c-.32 0-.583.274-.583.612V20.17c0 .336.26.61.582.61h13.78c.32 0 .583-.273.583-.61v-6.28c0-.415.336-.75.75-.75s.75.335.75.75v6.28c0 1.163-.934 2.11-2.084 2.11z",
  "M8.18 16.99c-.19.154-.476.032-.504-.21-.137-1.214-.234-4.053 1.483-5.943.908-1 3.02-1.52 4.475-.198s1.14 3.473.23 4.473c-2.07 2.15-3.428.058-5.686 1.878z",
];


export const MyMenu = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Explore = () => {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState(null);
  const user = useSelector((state) => state.profile.user);
  const theme = useSelector((state) => state.theme);


  useEffect(() => {
    // const onDocumentClick = () => {
    //   setUsers(null);
    // };
    // document.addEventListener("click", onDocumentClick);
    //
    // return () => {
    //   document.removeEventListener("click", onDocumentClick);
    // };
  }, []);


  const handleSearch = async (e) => {
    setSearch(e.target.value);
    const res = await exploreSearch(e.target.value);
    setUsers(res.data.users);
  };

  return (

      <div>
        <MyMenu>
          <div>
            <Search bg={theme.bg}>
              <SearchOutlined  style={{ fontSize: '24px' }}/>
              <input
                  placeholder="جستجو در آواگر"
                  value={search}
                  style={{caretColor: theme.color, color: theme.color}}
                  onChange={handleSearch}
              />
            </Search>
          </div>
          <div>

          </div>
          <div>
            <Space size="middle">
              <Link to={`/wallet`}>
                <Avatar shape="square" size="large" ><WalletOutlined style={{ fontSize: '24px' }}/> </Avatar>
              </Link>
              <Link to={`/chat`}>
                <Avatar shape="square" size="large" ><MessageOutlined style={{ fontSize: '24px' }}/> </Avatar>
              </Link>
            <Link to={`/profile/${user.username}`}>
                  <Avatar shape="square" size="large" ><AvatarProfile size={39} marginLeft={0} avatar={user.avatar}/> </Avatar>
            </Link>
              <Link to={`/notifications`}>
                <NotificationBtn/>
              </Link>
            </Space>
          </div>
        </MyMenu>

      <div >

        {users && !users.length && (
          <AutoComplete boxShadow={theme.boxShadow}>
            <h3
              style={{
                textAlign: "center",
                fontWeight: 700,
                color: theme.color,
              }}
            >
              No results
            </h3>
          </AutoComplete>
        )}
        {users && users.length && (
          <AutoComplete boxShadow={theme.boxShadow}>
            {users.map((item) => (
              <Link key={item.id} to={`/profile/${item.username}`}>
                <PeopleFlex key={item.id}>
                  <div>
                    <Avatar avatar={item.avatar} />
                  </div>
                  <div style={{ width: "100%" }}>
                    <PeopleDetails>
                      <div>
                        <object>
                          <Link to={`/profile/${item.username}`}>
                            <h3 style={{ color: theme.color }}>
                              {item.firstname} {item.lastname}
                            </h3>
                          </Link>
                        </object>
                        <object>
                          <Link to={`/profile/${item.username}`}>
                            <p>@{item.username}</p>
                          </Link>
                        </object>
                      </div>
                      {/* <div>Following</div> */}
                    </PeopleDetails>
                    <div>
                      <p>{item.bio}</p>
                    </div>
                  </div>
                </PeopleFlex>
              </Link>
            ))}
          </AutoComplete>
        )}
        {/*{!users && (
          <h2
            style={{ textAlign: "center", fontWeight: 700, color: theme.color }}
          >
            Search for users
          </h2>
        )}*/}
      </div>
</div>
  );
};

export default Explore;
