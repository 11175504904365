export const baseURL = process.env.REACT_APP_SERVER_URL ||  'http://localhost:5001';

// export const baseURL = process.env.REACT_APP_SERVER_URL || 'http://localhost:35000';
export const appConfig = {
    "chat": {
        "streamManagement": {
            "enable": true
        }
    },
    "debug": {
        "mode": 1
    },
    "social": { "server": baseURL},
    "conference": { "server": "wss://localhost:8189" },
    "endpoints": {
        "api": "https://localhost:5443/api",
        "chat": "host.docker.internal",
        "muc": "muc.chat.connectycube.com",
        "socketServer": 'wss://localhost:5443'
    },
    "hash": "sha1",
    "chatProtocol": {
        "bosh": "https://chat.connectycube.com:5281",
        "websocket": "wss://127.0.0.1:5443/ws/",
        "active": 2
    }
}
export const CREDENTIALS ={
    //  appId: process.env.REACT_APP_CONNECTYCUBE_APP_ID,
    appId: 218,
    authKey: "p-cqm2aatn8ZPM3",
    authSecret: "3hmGuXN8AHZOgg6",
};
