import React, {useEffect, useState} from 'react';
import {BellOutlined} from "@ant-design/icons";
import { Avatar, Badge, Space } from 'antd';
import {getNotificationsSummary} from "../requests/ava";

function NotificationBtn(props) {
    const [unreadCount, setUnreadCount] = useState(0);
    useEffect(() => {
        getNotificationsSummary().then(res=>{
            if(res.data && res.data.unreadCount)
                setUnreadCount(res.data.unreadCount);

        });

    }, []);
    return (


            <Badge count={unreadCount}>
                <Avatar shape="square" size="large" >
                    {/*<BellOutlined style={{ fontSize: '24px' }}/>*/}
                </Avatar>
            </Badge>



    );
}

export default NotificationBtn;
