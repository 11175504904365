// const config = require('./cubeConfig')
const {baseURL} = require("../constants");
const axios = require('axios').default
// const Utils = require('./cubeInternalUtils')

class SocialService {
    constructor(proxy) {
        // this.proxy = axios // || require axios
        axios.defaults.baseURL = baseURL;
        let self = this;
        axios.interceptors.request.use(function (config) {
            // Do something before request is sent
            // const token = self.getCookie('token');
            // config.headers['Authorization'] = `Bearer ${token}`;
            // console.log(config)
            return config;
        }, function (error) {
            // Do something with request error
            return Promise.reject(error);
        });

        // console.log(this.getCookie('token'))
    }
    getCookie(name) {
        if (typeof window !== 'undefined') {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
    }
    setBaseUrl = (baseURL)=>{
        axios.defaults.baseURL = baseURL;
    }
    axios = (baseURL)=>{
        return  axios;
    }
    getCancelToken = () => {
        return axios.CancelToken;
    }
    setToken = (token) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    comment = {
        add: (data) => axios.post(`/ava/comment/add`, data),
        getComments: (avaId) => axios.get(`/ava/comment/get-comments?avaId=${avaId}`)
    }
    hamava = {
        add: (avaId) => axios.post(`/ava/hamava/add`, {avaId}),
        del: (avaId) => axios.delete(`/ava/hamava/remove`, {data: {avaId}}),
        get: (avaId) => axios.get(`/ava/hamava/get-hamavas?avaId=${avaId}`),
    }
    like = {
        add: (avaId) => axios.post(`/ava/like/add`, {avaId}),
        del: (avaId) => axios.delete(`/ava/like/remove`, {data: {avaId,},})
    }
    bookmark = {
        add: (avaId) => axios.post(`/bookmarks/add`, {avaId}),
        del: (avaId) => axios.delete(`/bookmarks/remove`, {data: {avaId,},}),
        get: () => axios.get(`/bookmarks`)
    }
    notofication = {
        get: () => axios.get(`/user/notifications`),
        getSummary:  () => axios.get(`/user/notifications/summary`),
        read: (readId) => axios.post(`/user/notifications/read`, {readId}),
    }
    feed = {
        get: () => axios.get(`/feed/feed`),
    }
    timeline = {
        get: (userId) => axios.get(`/feed/timeline?userId=${userId}`)
    }
    media = {
        get: (userId) => axios.get(`/user/get-media?userId=${userId}`)
    }
    user = {
        getUser: (username) => axios.get(`/user/get-user?username=${username}`),
        getLikes: (userId) => axios.get(`/user/get-likes?userId=${userId}`),
        exploreSearch: (terms) => axios.get(`/explore?search=${terms}`),
        whoToFollow: ({userId, cancelToken}) => axios.get(`/feed/who-follow?userId=${userId}`, {cancelToken}),
        followDetails: (userId) => axios.get(`/follow/details?id=${userId}`),
        followToggle: (followedId) => axios.post(`/follow`, {followedId},),
        registerUser: (data) => axios.post(`/user/add-user`, data),
        loginUser: (data) => axios.post(`/user/login-user`, data),
        userEdit: (data) => axios.put(`/user/edit-user`, data),
    }
    ava = {
        add: (data) => axios.post(`/ava/add-ava`, data),
        get: (avaId) => axios.get(`/ava/get-ava?avaId=${avaId}`),

    }
    attach = {
        get: (model,attachId) => axios.get(`/ava/attach/${model}/${attachId}/get`),

    }

}

module.exports = SocialService
