"use client";

import SocialService  from "./cubeSocial";
const AvaCube = new SocialService();
// import {appConfig,CREDENTIALS , baseURL} from "../constants";

// AvaCube.init(CREDENTIALS,appConfig);

/*AvaCube.setBaseUrl(baseURL);*/


if (typeof window !== 'undefined') {
    // Perform localStorage action
    if (localStorage.getItem("token")) {
        AvaCube.setToken(localStorage.getItem("token"));
    }
}

export const getCancelToken = () => {
    return  AvaCube.getCancelToken();
}
export const setToken = (token) => {
    localStorage.setItem("token", token);
    AvaCube.setToken(token);
}


export const getAva = AvaCube.ava.get;
export const commentAdd = AvaCube.comment.add;
export const deleteHamava = AvaCube.hamava.del;
export const deleteLike = AvaCube.like.del;
export const deleteBookmark = AvaCube.bookmark.del;
export const hamavaAdd = AvaCube.hamava.add;
export const likeAdd = AvaCube.like.add;
export const bookmarkAdd = AvaCube.bookmark.add;
export const getHamavas = AvaCube.hamava.get;
export const getBookmarks = AvaCube.bookmark.get;
export const getNotifications = AvaCube.notofication.get;
export const getNotificationsSummary = AvaCube.notofication.getSummary;
export const readNotification = AvaCube.notofication.read;
export const getFeed = AvaCube.feed.get;
export const getAttach = AvaCube.attach.get;
export const getTimeline = AvaCube.timeline.get;
export const getMedia = AvaCube.media.get;
export const userGetLikes = AvaCube.user.getLikes;
export const getLikes = AvaCube.like.get;
export const getComments = AvaCube.comment.getComments;
export const exploreSearch = AvaCube.user.exploreSearch;
export const getUser = AvaCube.user.getUser;
export const whoToFollow = AvaCube.user.whoToFollow;
export const followDetails = AvaCube.user.followDetails;
export const followToggle = AvaCube.user.followToggle;
export const avaAdd = AvaCube.ava.add;
export const registerUser = AvaCube.user.registerUser;
export const loginUser = AvaCube.user.loginUser;
export const userEdit = AvaCube.user.userEdit;





/*
* axios.defaults.baseURL = baseURL;
if (localStorage.getItem("token")) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
}
export const getCancelToken = () => {
    return axios.CancelToken;
}
export const setToken = (token) => {
    localStorage.setItem("token", token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}*/
/*
export const getAva = (avaId) => axios.get(`/ava/get-ava?avaId=${avaId}`);
export const commentAdd = (data) => axios.post(`/ava/comment/add`, data)
export const deleteHamava = (avaId) => axios.delete(`/ava/hamava/remove`, {data: {avaId,},});
export const deleteLike = (avaId) => axios.delete(`/ava/like/remove`, {data: {avaId,},});
export const deleteBookmark = (avaId) => axios.delete(`/bookmarks/remove`, {data: {avaId,},});
export const hamavaAdd = (avaId) => axios.post(`/ava/hamava/add`, {avaId});
export const likeAdd = (avaId) => axios.post(`/ava/like/add`, {avaId});
export const bookmarkAdd = (avaId) => axios.post(`/bookmarks/add`, {avaId});
export const getHamavas = (avaId) => axios.get(`/ava/hamava/get-hamavas?avaId=${avaId}`);
export const getBookmarks = () => axios.get(`/bookmarks`);
export const getNotifications = () => axios.get(`/user/notifications`);
export const getNotificationsSummary = () => axios.get(`/user/notifications/summary`);
export const readNotification = (readId) => axios.post(`/user/notifications/read`,{readId});
export const getFeed = () => axios.get(`/feed/feed`);
export const getTimeline = (userId) => axios.get(`/feed/timeline?userId=${userId}`);
export const getMedia = (userId) => axios.get(`/user/get-media?userId=${userId}`);
export const userGetLikes = (userId) => axios.get(`/user/get-likes?userId=${userId}`);
export const getLikes = (avaId) => axios.get(`/ava/like/get-likes?avaId=${avaId}`);
export const getComments = (avaId) => axios.get(`/ava/comment/get-comments?avaId=${avaId}`);
export const exploreSearch = (terms) => axios.get(`/explore?search=${terms}`);
export const getUser = (username) => axios.get(`/user/get-user?username=${username}`);
export const whoToFollow = ({userId, cancelToken}) => axios.get(`/feed/who-follow?userId=${userId}`, {cancelToken});
export const followDetails = (userId) => axios.get(`/follow/details?id=${userId}`);
export const followToggle = (followedId) => axios.post(`/follow`, {followedId},);
export const avaAdd = (data) => axios.post(`/ava/add-ava`, data);
export const registerUser = (data) => axios.post(`/user/add-user`, data);
export const loginUser = (data) => axios.post(`/user/login-user`, data);
export const userEdit = (data) => axios.put(`/user/edit-user`, data);
*/
